<template>
  <v-container fluid class="push-order-settings-page">
    <div v-if="currentShop === null && isLoading">
      <data-preparation-overlay></data-preparation-overlay>
    </div>

    <div v-else-if="isLoading">
      <order-module-data-loading-overlay></order-module-data-loading-overlay>
    </div>

    <v-overlay v-if="showUnsavedChangesAlert">
      <v-card light class="pa-5 pl-3 pr-3">
        <v-card-title><h2>Unsaved Changes</h2></v-card-title>
        <v-card-text>If you leave this page, any unsaved changes will be lost.</v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn outlined class="action-btn float-right leave-btn" @click="leavePageNow()" v-if="routeTo != null">
                Leave
              </v-btn>
              <v-btn v-else outlined class="action-btn float-right leave-btn" @click="fetchPushSettings()">
                Leave
              </v-btn>
              <v-btn light outlined class="action-btn float-right mr-3" @click="closeUnsaveChangesAlertAndCancelLeave()">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <div class="setting-body">
      <v-toolbar flat color="transparent">
        <v-btn class="transparent elevation-0 text--primary pl-1 pr-3" v-on:click="redirectToOrderPage()">
          <v-icon class="icon text--primary mr-1">keyboard_arrow_left</v-icon>
          Back to Orders
        </v-btn>
      </v-toolbar>

      <h1 class="pl-5 d-flex">
        <v-icon class="mr-3">mdi-cog</v-icon>
        <span>Order Push Settings</span>
      </h1>

      <v-container class="mt-4">
        <v-row>
          <v-col cols="3" class="push-setting-nav">
            <v-list class="pt-0 pb-0">
              <v-list-item
                outlined
                active-class="primary-text"
                v-for="config in visibleSettings"
                :class="{ 'active' : (currentConfig != null && config.key === currentConfig.key) }"
                :key="config.id"
                class="config-block"
                :data-class="config.key"
                @click="activateConfigBody(config)">
                  <template v-if="config.key === 'custom_shipping_tags'">
                    Shipping Type Tags
                  </template>
                  <template v-else-if="config.key !== 'order_tax_adjustment'">
                    {{ config.title }}
                  </template>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="9" class="push-setting-body">
            <v-card outlined color="transparent" class="config-body content pt-0" v-if="currentConfig != null">

              <div v-if="currentConfig.key === 'push_order_email_option'">
                <h2>E-mail Contact Method</h2>
                <p>Select which email to be pushed with an order. The selected e-mail will receive any relevant notification e-mails from the source store, including when an order is fulfilled.</p>
                <v-radio-group v-model="emailOption" @change="updateSettings()">
                  <v-radio value="destination_store_email" class="email-option-block">
                    <template v-slot:label>
                      <div class="custom-label">
                        <h4>{{ currentShop.email }}</h4>
                        <div>Use your destination store's e-mail address.</div>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="customer_email" class="email-option-block">
                    <template v-slot:label>
                      <div class="custom-label">
                        <h4>Customer's E-mail</h4>
                        <div>
                          <span>Use customer's e-mail entered during checkout.</span><br>
                          <span class="subtitle-2">Note: Customers will receive fulfillment e-mails from source store.</span>
                        </div>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="custom_email" class="email-option-block">
                    <template v-slot:label>
                      <div class="custom-label">
                        <h4>Other:</h4>
                        <v-text-field
                          outlined
                          dense
                          placeholder="Enter an E-mail Address"
                          v-model="customEmailValue"
                          class="custom_email_input"
                          @change="updateSettings()"
                          :error-messages="checkEmailErrors">
                        </v-text-field>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>

              <div v-else-if="currentConfig.key === 'push_order_shipping_rules'">
                <h2>Shipping Rate Rules</h2>
                <p>By default, we will auto push orders with $0 shipping fees to source stores. <br> Manage and set rules below if you want to push orders with a flat rate based on order value. Note that this rate will be sent to the source store with your store's local currency.</p>
                <v-card class="shipping-rates-list pa-4" light outlined flat>
                  <v-list class="pl-5 pr-5">
                    <v-list-item class="shipping-rates-list-item-title">
                      <v-row>
                        <v-col offset="1" cols="5">Shipping Rate</v-col>
                        <v-col cols="6">Order Value</v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item v-for="(shippingRule, index) in shippingRules" :key="index">
                      <v-row>
                        <v-col offset="1" cols="5">${{ parseFloat(shippingRule.shipping_cost).toFixed(2)  }}</v-col>
                        <v-col cols="6">
                          <div v-if="(typeof(shippingRules[index +1]) != 'undefined')" class="float-left">
                            ${{ parseFloat(shippingRule.min_order_total).toFixed(2) }} to ${{ parseFloat(shippingRules[index +1].min_order_total).toFixed(2) }}
                          </div>
                          <div v-else class="float-left">
                            ${{ parseFloat(shippingRule.min_order_total).toFixed(2) }} and up
                          </div>
                          <v-icon class="float-right" @click="removeShippingRule(index)">mdi-minus-circle-outline</v-icon>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item :class="shippingRules.length === 0 ? 'mt-5' : ''">
                      <v-row>
                        <v-col cols="1">
                          <v-icon class="float-left add-new-shipping-btn mr-5" @click="addingNewShippingRule()">mdi-plus-circle-outline</v-icon>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            v-model="newShippingCost"
                            outlined
                            dense
                            class="new-shipping-rules-input float-left"
                            placeholder="A$ 0.00"
                            :error-messages="checkNewShippingCostErrors"
                            @change="updateSettings()">
                          </v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            v-model="newShippingCostMinOrderTotal"
                            outlined
                            dense
                            class="new-shipping-rules-input float-left"
                            placeholder="A$ Min"
                            :error-messages="checkNewShippingCostMinOrderTotalErrors"
                            @change="updateSettings()">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>

              <div v-else-if="currentConfig.key === 'custom_shipping_tags'">
                <v-row class="shipping-tags-header">
                  <v-col cols="8">
                    <h2>Shipping Type Tags</h2>
                    <p>Convert shipping type into tags to be pushed to Source Stores. <a href="http://help.syncio.co/en/articles/5735293-order-push-custom-shipping-tags" target="_blank">Learn More.</a></p>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <v-btn :loading="isTagsLoading"  elevation="0" :disabled="!isTagsUpdated" class="btn-primary px-5" @click="updateCustomShippingTagsHandler">Save Changes</v-btn>
                  </v-col>
                </v-row>

                <v-card class="shipping-tags not-a-link py-7 px-2" outlined>
                  <div class="px-6">
                    <v-row class="shipping-tags-title mb-2" align="center">
                      <v-col cols="6" class="px-0 text-uppercase">
                        Shipping Type
                      </v-col>
                      <v-col cols="6" class="px-0 text-right text-uppercase">
                        Tags Attached
                      </v-col>
                    </v-row>

                    <v-row v-for="tag in tagConfigs" :key="tag.title" class="mt-2" align="center">
                      <v-col cols="6" class="px-0">
                        {{ tag.title }}
                      </v-col>
                      <v-col cols="6" class="px-0 text-right">
                        <template v-if="tag.tag === '' && !tag.isEditMode">
                          <span class="shipping-tags-tag px-3 d-inline-block">
                            {{ tag.title | limitCharacters(40) }}
                            <v-icon @click.prevent="tagEditHandler(tag)" size="14px" class="ml-1 pointer" color="#005072">edit</v-icon>
                          </span>
                        </template>

                        <template v-else-if="tag.tag !== '' && !tag.isEditMode">
                          <span class="shipping-tags-tag px-3 d-inline-block">
                            {{ tag.tag }}
                            <v-icon @click.prevent="tagEditHandler(tag)" size="14px" class="ml-1 pointer" color="#005072">edit</v-icon>
                          </span>
                        </template>

                        <template v-else-if="tag.isEditMode">
                          <div class="edit-tag-container relative">
                            <input @keyup="updateTagHandler(tag, $event)" @blur="updateTag(tag)" class="shipping-tags-input pl-3" type="text" v-model="tag.updatedTag" maxlength="40" :ref="tag.title" />
                            <v-icon @click="updateTagByIcon(tag)" size="14px" class="absolute ml-1 pointer" color="#161616">close</v-icon>
                          </div>
                          <span v-if="!tag.isValid" class="tag-error">
                            Max limit reached (40 characters)
                          </span>
                        </template>

                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>

              <div v-else-if="currentConfig.key === 'order_tax_adjustment'">
                <v-row class="shipping-tags-header">
                  <v-col cols="12" class="pb-0">
                    <h2>Order Value Rules</h2>
                    <p>Manage how Source Stores will receive the order.</p>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <h3 class="mt-2 mb-2">Tax</h3>
                    <v-card class="px-6 pb-4 pt-3 d-flex align-center include-tax-card" elevation="0">
                      <v-switch class="mt-1" @change="updateVisibleSettingsOnTaxUpdate" v-model="isTaxIncluded" inset hide-details color="blue darken-1" :label="isTaxIncluded ? 'On' : 'Off'"></v-switch>
                      <div class="ml-6">
                        <h4>Include Tax</h4>
                        <p class="mb-0">Order will be pushed with tax included in the total price</p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>

              <div v-else>
                <strong>{{ currentConfig.title }}</strong> configuration not supported.
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="submit-header" v-if="isDirty && !this.isTagsUpdated && !this.isLoading">
      <v-row>
        <v-col cols="12">
          <span class="float-left unsave-bar-title">Unsaved Changes</span>
          <v-btn light class="action-btn float-right" @click="submitCurrentConfigUpdate()">Save</v-btn>
          <v-btn light outlined class="action-btn float-right mr-5 leave-btn" @click="resetCurrentConfig()">Discard</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { email, decimal, minValue } from 'vuelidate/lib/validators';
import { FETCH_PUSH_SETTINGS, ADD_NOTIFICATION } from '@/store/actions.type';
import { mapState, mapGetters } from 'vuex';
import DataPreparationOverlay from '@/views/components/DataPreparationOverlay';
import OrderApiService from '@/common/api/order.service';
import OrderModuleDataLoadingOverlay from '../components/OrderModuleDataLoadingOverlay';

export default {
  name: "PushOrderSettingsPage",

  validations: {
    customEmailValue: { email },
    newShippingCost: {
      decimal,
      minValue: minValue(0.0),
    },
    newShippingCostMinOrderTotal: {
      decimal,
      minValue: minValue(0.0),
    },
  },

  data() {
    return {
      currentConfig: null,
      customEmailValue: null,
      emailOption: "customer_email",
      forceLeaving: false,
      isLoading: false,
      isTagsLoading: false,
      isTagsUpdated: false,
      newShippingCost: '',
      newShippingCostMinOrderTotal: '',
      oldCurrentConfig: null,
      oldSettings: [],
      routeTo: null,
      shippingRules: [],
      showUnsavedChangesAlert: false,
      tagConfigs: [],
      visibleSettings: [],
      isClearTagClicked: false,
      taxSetting: [],
      includeTax: true
    };
  },

  created() {
    if (this.pushSettings != null) {
      this.fetchPushSettings();
    } else {
      this.$store.dispatch(`shop/loadCurrentShop`).then((data) => {
        if (data.success){
          this.fetchPushSettings();
        }
      });
    }
  },

  components: {
    DataPreparationOverlay,
    OrderModuleDataLoadingOverlay,
  },

  computed: {
    ...mapState('orders', ['pushSettings']),
    ...mapGetters("shop", ["currentShop"]),
    ...mapGetters({
      tagConfigsGetters: "orders_2_0/GET_CUSTOM_TAGS"
    }),

    isDirty() {
      let newSettingsString = JSON.stringify(this.visibleSettings);
      let oldSettingsString = JSON.stringify(this.oldSettings);
      return ((newSettingsString != oldSettingsString) || this.isTagsUpdated);
    },

    checkEmailErrors () {
      const emailErrors = [];
      if (!this.$v.customEmailValue.$dirty) return emailErrors
      !this.$v.customEmailValue.email && emailErrors.push('Must be valid e-mail')
      return emailErrors
    },

    checkNewShippingCostErrors () {
      const newShippingCostErrors = [];
      if (!this.$v.newShippingCost.$dirty) return newShippingCostErrors
      !this.$v.newShippingCost.decimal && newShippingCostErrors.push('Must be a valid number')
      !this.$v.newShippingCost.minValue && newShippingCostErrors.push('Must be equal or bigger than zero')
      return newShippingCostErrors
    },

    checkNewShippingCostMinOrderTotalErrors () {
      const newShippingCostMinOrderTotalErrors = [];
      if (!this.$v.newShippingCostMinOrderTotal.$dirty) return newShippingCostMinOrderTotalErrors
      !this.$v.newShippingCostMinOrderTotal.decimal && newShippingCostMinOrderTotalErrors.push('Must be a valid number')
      !this.$v.newShippingCostMinOrderTotal.minValue && newShippingCostMinOrderTotalErrors.push('Must be equal or bigger than zero')
      return newShippingCostMinOrderTotalErrors
    },

    isTaxIncluded: {
      get() {
        let taxSetting = this.visibleSettings.filter(setting => {
          return setting.key === 'order_tax_adjustment'
        });
        return taxSetting[0] && taxSetting[0].value;
      },

      set(value) {
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    this.oldCurrentConfig = this.currentConfig;
    if(this.isDirty && !this.forceLeaving) {
      this.showUnsavedChangesAlert = true;
      this.routeTo = to;
      // next(false);
    } else {
      next();
    }
  },

  methods: {
    updateTag(tag) {
      setTimeout(() => {
        if(this.isClearTagClicked) {
          tag.updatedTag = tag.oldValue;
          document.activeElement.previousElementSibling.blur();
          this.isClearTagClicked = false;
        } else {
        tag.updatedTag !== '' ? tag.tag = tag.updatedTag : tag.tag = tag.oldValue;
          if(tag.tag !== tag.oldValue) {
            this.isTagsUpdated = true;
          }
          tag.isEditMode = false;
        }
      }, 10);
    },

    updateTagHandler(tag, event) {
      if(tag.updatedTag.length >= 40) {
        tag.isValid = false;
      } else {
        tag.isValid = true;
      }

      if(event.keyCode === 13) {
        tag.isValid = true;
        this.updateTag(tag);
      }
    },

    updateTagByIcon(tag) {
      this.isClearTagClicked = true;
      tag.isValid = true;
      this.updateTag(tag);
    },

    tagEditHandler(tag) {
      tag.updatedTag = '';
      tag.isValid = true;
      tag.oldValue = tag.tag || tag.title;
      tag.isEditMode = true;
      this.$nextTick(() => {
        this.$refs[`${tag.title}`][0].focus();
      });
    },

    updateVisibleSettingsOnTaxUpdate() {
      this.taxSetting = this.visibleSettings.filter(setting => {
        return setting.key === 'order_tax_adjustment'
      });

      this.taxSetting[0].value = !this.taxSetting[0].value;
    },

    async updateCustomShippingTagsHandler() {
      this.isTagsLoading = true;
      this.showUnsavedChangesAlert = false;
      let custom_shipping_tags = {};

      this.tagConfigs.forEach(({ title, tag }) => {
        Object.defineProperty(custom_shipping_tags, title, {
          enumerable: true,
          value: [tag],
          writable: true
        });
      });

      await this.$store.dispatch('orders_2_0/updateCustomShippingTags', {
        store_id: this.currentShop.id,
        custom_shipping_tags
      });

      this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
        notification: {
          id: 'N' + (Math.floor(Math.random() * 100000000)),
          position: "bottom-left",
          type: "success",
          body: this.currentConfig.title + "'s updated!",
          state: 0,
          length: 5000,
          data: null,
        },
      });

      this.isTagsLoading = false;
      this.isTagsUpdated = false;
    },

    async fetchPushSettings() {
      this.showUnsavedChangesAlert = false;
      this.isLoading = true;
      this.isTagsUpdated = false;
      this.visibleSettings = [];

      await this.$store.dispatch(`orders/${FETCH_PUSH_SETTINGS}`, { storeId: this.currentShop.id}).then(() => {
        this.pushSettings.forEach((setting) => {
          if (setting.key != 'auto_push_order') {
            this.visibleSettings.push(setting);
            if (this.currentConfig === null) {
              this.currentConfig = setting;
            }
            if (this.currentConfig.key === setting.key) {
              // make sure it refresh the data memeory address to allow binding
              this.currentConfig = setting;
            }
          }
          if (setting.key === 'push_order_email_option') {
            this.emailOption = setting.value;
            this.customEmailValue = setting.custom_value;
          }
          if (setting.key === 'push_order_shipping_rules') {
            this.shippingRules = setting.value;
          }
        });
        this.oldSettings = JSON.parse(JSON.stringify(this.visibleSettings));
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });

      await this.$store.dispatch('orders_2_0/fetchCustomShippingTags', { store_id: this.currentShop.id }).then(() => {
        this.tagConfigs = [];
        this.tagConfigs = [...this.tagConfigs, ...this.tagConfigsGetters];
      });
    },

    submitCurrentConfigUpdate() {
      this.$v.$touch();
      this.isLoading = true;
      if ((this.currentConfig != null && this.currentConfig.value != 'custom_email') || (this.currentConfig.value === 'custom_email' && !this.$v.$invalid)) {
        let configValue = {};
        if(this.currentConfig.key === 'custom_shipping_tags') {
          this.currentConfig.value.forEach(value => {
            Object.defineProperty(configValue, value.title, {
            enumerable: true,
              value: [value.tag],
              writable: true
            });
          });
        } else {
          configValue = this.currentConfig.value;
        }
        if (configValue === 'custom_email') {
          configValue += ':'+ this.customEmailValue;
        }
        OrderApiService.saveSinglePushSetting(this.currentShop.id, this.currentConfig.id, configValue).then(({ data }) => {
          if (data.success) {
            this.fetchPushSettings();
            this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
              notification: {
                id: 'N' + (Math.floor(Math.random() * 100000000)),
                position: "bottom-left",
                type: "success",
                body: this.currentConfig.title + "'s updated!",
                state: 0,
                length: 6000, // seconds
                data: null,
              },
            });
          } else {
            this.isLoading = false;
          }
        }).catch((error) => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    },

    redirectToOrderPage() {
      this.$router.push({ name: "OrderManagementPage" });
    },

    activateConfigBody(config) {
      this.oldCurrentConfig = this.currentConfig;
      if (this.isDirty) {
        this.showUnsavedChangesAlert = true;
        this.routeTo = null;
      }
      this.currentConfig = config;
    },

    updateSettings() {
      if (this.currentConfig.key === 'push_order_email_option') {
        this.currentConfig.custom_value = null;
        this.currentConfig.value = this.emailOption;
        if (this.emailOption === 'custom_email') {
          this.$v.customEmailValue.$touch();
          this.currentConfig.custom_value = this.customEmailValue;
        }
      } else if (this.currentConfig.key === 'push_order_shipping_rules') {
        this.$v.newShippingCost.$touch();
        this.$v.newShippingCostMinOrderTotal.$touch();
      }
    },

    resetCurrentConfig() {
      this.oldSettings.forEach((setting, index) => {
        switch(setting.key) {
          case 'push_order_email_option':
            this.visibleSettings[index].custom_value = setting.custom_value;
            this.emailOption = setting.value;
            if (this.currentConfig.key === 'push_order_email_option') {
              this.currentConfig.value = this.emailOption;
            }
            break;

          case 'push_order_shipping_rules':
            this.shippingRules = JSON.parse(JSON.stringify(setting.value));
            this.visibleSettings[index].value = this.shippingRules;
            break;
          default:
            this.visibleSettings[index].value = setting.value;
        }
      });
    },

    leavePageNow() {
      if (this.routeTo != null) {
        this.forceLeaving = true;
        this.$router.push(this.routeTo);
        this.isTagsUpdated = false;
      }
    },

    closeUnsaveChangesAlertAndCancelLeave() {
      this.showUnsavedChangesAlert = false;
      this.visibleSettings.forEach((setting) => {
        if (this.oldCurrentConfig != null && setting.key === this.oldCurrentConfig.key) {
          this.currentConfig = setting;
        }
      })
    },

    addingNewShippingRule() {
      if (this.$v.newShippingCost.$invalid) {
        return;
      }

      if (this.newShippingCost != '' && this.newShippingCostMinOrderTotal != '') {
        this.shippingRules.push({
          min_order_total: this.newShippingCostMinOrderTotal,
          shipping_cost: this.newShippingCost,
        });
        this.shippingRules.sort((a, b) => {
          return a.min_order_total - b.min_order_total;
        });
        this.newShippingCost = '';
        this.newShippingCostMinOrderTotal = '';
      }
    },

    removeShippingRule(index) {
      this.shippingRules.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.push-order-settings-page {
  max-width: 1100px;

  .setting-body {
    min-height: 800px;
  }

  .back-to-text {
    line-height: 24px;
    display: inline-block;
  }

  .config-block {
    border: 1px solid #ccc;
    &:first-child {
      border-bottom: 0px;
      border-radius: 5px 5px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 5px 5px;
    }
    &.active  {
      border-left: 5px solid #0e3b4d;
    }
    &[data-class="order_tax_adjustment"] {
      display: none;
    }
  }

  .push-setting-body {
    .config-body {
      .email-option-block {
        padding: 30px 20px;
        margin: 0px;
        border: 1px solid #ccc;
        border-bottom: 0px;
        background-color: #fff;

        .custom-label {
          width: 100%;
          padding-right: 20px;
          display: inline-block;
          margin-left: 20px;

          h4 {
            width: 100%;
          }

          .custom_email_input {
            border-radius: 3px;
            margin-top: 10px;
          }
        }

        &:first-child {
          border-radius: 8px 8px 0px 0px;
        }

        &:last-child {
          border-bottom: 1px solid #ccc;
          border-radius: 0px 0px 8px 8px;
        }
      }
    }

    .shipping-rates-list {
      .shipping-rates-list-item-title {
        border-bottom: 1px solid #ccc;
        font-weight: 600;
      }

      .add-new-shipping-btn {
        line-height: 37px;
      }

      .new-shipping-rules-input {
        border-radius: 3px;
      }
    }
  }

  .action-btn {
    border-radius: 3px;
  }

  .leave-btn {
    background-color: #fcb058;
  }

  .submit-header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: #0e3b4d;
    z-index: 5;
    padding: 15px 20px;

    .action-btn {
      border-radius: 3px;
      background-color: #fcb058;
    }

    .leave-btn {
      color: #fff;
      background-color: transparent;
    }

    .unsave-bar-title {
      padding-left: 20px;
      color: #fff;
      line-height: 36px;
      font-weight: 600;
    }
  }

  .include-tax-card {
    border: 1px solid #ccc;
    border-radius: 6px;
  }
}
</style>
